import { mapState, mapActions } from 'vuex'

export default {

  name: 'TFilter',

  components: {
    TFilterItem: () => import('@/components/filter/item.vue'),
  },

  props: {

    applyFilter: {
      type: Function
    },

    filterUpdated: {
      type: Function
    },

    form: {
      type: Object,
      default: () => {}
    },

    filterItems: {
      type: Array
    }
  },

  data () {
    return {
      errorDate: false,
    }
  },
  methods: {
    ...mapActions('filter', [ 'setPaymentsList', 'setMetaDataPaymentsList', 'addItem', 'toggleLoading' ]),
    bindjQueryPlugins () {
      $('.tui.ui.dropdown').dropdown()
    },

    clearForm () {
      $('.tui.ui.dropdown').dropdown('clear');
      this.form = {}
    }
  },
  computed: {
    ...mapState({
      isLoading: ({ filter }) => filter.isLoading
    })
  },
  mounted() {
    this.bindjQueryPlugins()
    if (this.form && !this.form.equalTo) {
      this.form.equalTo = { key: '=', text: 'igual a' }
    }
  },
  updated () {
    if (this.form && !this.form.equalTo) {
      this.form.equalTo = { key: '=', text: 'igual a' }
    }
  }
}
