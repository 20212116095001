var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "filter", staticClass: "tui tuim ui vertical menu filter" },
    [
      _vm.filterItems
        ? _vm._l(_vm.filterItems, function(item, k) {
            return _c("t-filter-item", {
              key: k,
              attrs: { item: item },
              on: { filterUpdated: _vm.filterUpdated }
            })
          })
        : _vm._e(),
      _c(
        "form",
        {
          staticClass: "tui tuim ui form filter",
          attrs: { method: "post" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.applyFilter($event)
            }
          }
        },
        [
          _vm._t("fields"),
          _c("button", {
            class:
              "tui tuim ui fluid primary " +
              (_vm.isLoading ? "loading" : "") +
              " " +
              (_vm.isLoading ? "disabled" : "") +
              " button",
            attrs: { type: "submit", disabled: _vm.isLoading },
            domProps: { innerHTML: _vm._s("Filtrar") }
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }